import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'sendEmailModal',
  {
    title: 'Enviar documentos',
    send: 'Enviar',
    smtp: {
      label: 'De',
      required: 'La cuenta de correo es requerida',
    },
    to: {
      label: 'Para',
      min: 'Especifica al menos un destinatario',
    },
    subject: {
      label: 'Asunto',
      required: 'El asunto del correo es requerido',
    },
    body: {
      label: 'Mensaje',
    },
    isProgrammed: {
      label: 'Programar envío',
    },
    programDate: {
      label: 'Fecha programada',
      required: 'La fecha programada es requerida mientras esté activo el campo "Programar envío"',
    },
  },
  true,
  false,
);

export default i18n;
