import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'header',
  {
    orders: 'Pedidos',
    users: 'Usuarios',
    clients: 'Clientes',
    suppliers: 'Proveedores',
    smpts: 'SMTP',
    configuration: 'Configuración',
    logout: 'Salir',
  },
  true,
  false,
);

export default i18n;
