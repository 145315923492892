import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'dateRangePicker',
  {
    today: 'Hoy',
    yesterday: 'Ayer',
    last7Days: 'Últimos 7 días',
    currentMonth: 'Mes actual',
    previousMonth: 'Mes anterior',
    firstQuarter: '1° trimestre',
    secondQuarter: '2° trimestre',
    thirdQuarter: '3° trimestre',
    fourthQuarter: '4° trimestre',
    currentYear: 'Año actual',
    previousYear: 'Año anterior',
  },
  true,
  false,
);

export default i18n;
