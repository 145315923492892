import { Autocomplete, AutocompleteChangeReason } from '@mui/material';
import Input from '../Input/Input';
import { DefaultOptionType, SelectProps } from './types';
import { SyntheticEvent } from 'react';

const Select = <OptionType extends DefaultOptionType>({
  id,
  options,
  disablePortal = true,
  sx,
  sxInput,
  label,
  includeErrorSpace,
  error,
  onChange,
  onClear,
  value,
  ...props
}: SelectProps<OptionType>) => {
  const handleChange = (
    e: SyntheticEvent<Element, Event>,
    value: OptionType | null,
    reason: AutocompleteChangeReason,
  ) => {
    e.stopPropagation();
    if (reason === 'clear' && onClear) onClear();
    if (reason === 'selectOption' && value) onChange(value);
  };

  return (
    <Autocomplete<OptionType>
      disablePortal={disablePortal}
      id={id}
      options={options}
      getOptionLabel={o => o.name}
      sx={sx}
      onChange={handleChange}
      value={value}
      //@ts-expect-error Bad typing on material autocomplete
      disableClearable={!onClear}
      {...props}
      renderInput={params => (
        <Input
          variant="outlined"
          {...params}
          label={label}
          includeErrorSpace={includeErrorSpace}
          helperText={error}
          error={!!error}
        />
      )}
    />
  );
};

export default Select;
