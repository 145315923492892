import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { StyledInput } from './styles';
import { DatepickerProps } from './types';

const Datepicker = ({ id, includeErrorSpace = true, ...props }: DatepickerProps) => {
  return (
    <FormControl variant="standard" sx={props.sx}>
      {props.label && (
        <InputLabel shrink htmlFor={id}>
          {props.label}
        </InputLabel>
      )}
      <StyledInput {...props} />
      {(includeErrorSpace || props.error) && <FormHelperText error>{props.error || ' '}</FormHelperText>}
    </FormControl>
  );
};

export default Datepicker;
