import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios from 'axios';
import type { AxiosError } from 'axios';
import { AxiosQuery, AxiosQueryRequest } from 'src/types/api';
import { getValue, removeItem } from 'src/utils/common/localStorage';

export const axiosBaseQuery =
  ({ baseURL }: AxiosQuery): BaseQueryFn<AxiosQueryRequest, unknown, unknown> =>
  async ({ url, method, data, params, headers }) => {
    try {
      const token = getValue('token');
      const result = await axios({
        url: baseURL + url,
        method,
        data,
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          ...(headers || {}),
        },
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      if (err.response?.status === 401) removeItem('token');
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
