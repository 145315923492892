import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import MENU from './menu';
import Logo from '../Logo/Logo';
import Navbar from './components/Navbar/Navbar';
import Profile from './components/Profile/Profile';
import './i18n';
import { MenuItem } from './styles';
import { useHeader } from './useHeader';
import AppBarMenu from './components/Menu/Menu';

const Header = () => {
  const { t } = useTranslation('header');
  const { mobileOpen, isOptionActive, handleDrawerToggle, handleNavigation } = useHeader();

  return (
    <>
      <AppBar component="nav">
        <Toolbar sx={{ gap: 2 }}>
          <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ display: { md: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Logo color="white" version="auto" />
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {MENU.map(item => (
                <AppBarMenu key={item.key} option={item} onNavigate={handleNavigation}>
                  <MenuItem
                    as="a"
                    // href={item.path}
                    onClick={handleNavigation(item.path)}
                    active={isOptionActive(item.path)}
                    color="inherit">
                    {t(item.key)}
                  </MenuItem>
                </AppBarMenu>
              ))}
            </Box>
          </Box>
          <Profile />
        </Toolbar>
      </AppBar>
      <Navbar isOpen={mobileOpen} onNavbarToggle={handleDrawerToggle} onNavigate={handleNavigation} />
    </>
  );
};

export default Header;
