import { FormControl, InputLabel } from '@mui/material';
import { StyledInput } from './styles';
import { InputProps } from './types';

const Input = ({ includeErrorSpace = true, ...props }: InputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!props.onChange) return;
    if (!props.regex) props.onChange(e);
    else {
      const isValid = props.regex.test(e.target.value);
      if (isValid) props.onChange(e);
    }
  };

  return (
    <FormControl variant="standard" fullWidth={props.fullWidth}>
      {props.label && (
        <InputLabel shrink htmlFor={props.id}>
          {props.label}
        </InputLabel>
      )}
      <StyledInput {...props} onChange={handleChange} helperText={props.helperText || (includeErrorSpace ? ' ' : '')} />
    </FormControl>
  );
};

export default Input;
