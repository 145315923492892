import { Button } from '@mui/material';
import styled, { css } from 'styled-components';

export const MenuItem = styled(Button)<{ active: boolean }>`
  margin: 0 4px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  line-height: 1.75;

  &&& {
    padding: 12px;
  }
  &&&:hover,
  &&&:focus {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.palette.secondary.dark};
    `}
`;
