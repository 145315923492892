import { useEffect, useState } from 'react';
import { DocumentLoadType } from './types';
import { useTheme } from 'styled-components';
import { useMediaQuery } from '@mui/material';
import { SIDE_PANEL_WIDTH } from './styles';

export const useDocumentViewer = () => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [pageWidth, setPageWidth] = useState(0);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null);
  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElem(event.currentTarget);
  };

  const handleCloseContextMenu = () => setAnchorElem(null);

  const handleCurrentTab = (e: React.SyntheticEvent<Element, Event>, tab: number) => setCurrentTab(tab);
  const onDocumentLoad = ({ numPages }: DocumentLoadType) => setNumberOfPages(numPages);

  const handleExpandPanel = () => setIsPanelOpen(prev => !prev);

  const calculateDimensions = () => {
    const vw = window.visualViewport?.width || 0;
    const panelWidth = isMdDown ? 0 : SIDE_PANEL_WIDTH;
    setPageWidth(vw - panelWidth - 32);
    if (isSmDown) setIsPanelOpen(false);
  };

  useEffect(() => {
    calculateDimensions();
    window.addEventListener('resize', calculateDimensions);
    return () => window.removeEventListener('resize', calculateDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isPanelOpen,
    isSmDown,
    currentTab,
    numberOfPages,
    pageWidth,
    anchorElem,
    handleContextMenu,
    handleCloseContextMenu,
    handleCurrentTab,
    onDocumentLoad,
    handleExpandPanel,
  };
};
