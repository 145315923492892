import styled from 'styled-components';

export const AutocompleteListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > * {
    width: 100%;
    text-align: left;
  }
`;
