import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Order, OrdersStore } from 'src/types/orders';

const initialState: OrdersStore = {
  order: null,
  isEditModalOpen: false,
  isDetailModalOpen: false,
  isSendModalOpen: false,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    openEditModal: (state: OrdersStore, action: PayloadAction<Order | null>) => {
      state.order = action.payload;
      state.isEditModalOpen = true;
    },
    closeEditModal: (state: OrdersStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.order = null;
      state.isEditModalOpen = false;
    },
    openDetailModal: (state: OrdersStore, action: PayloadAction<Order | null>) => {
      state.order = action.payload;
      state.isDetailModalOpen = true;
    },
    closeDetailModal: (state: OrdersStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.order = null;
      state.isDetailModalOpen = false;
    },
    openSendModal: (state: OrdersStore, action: PayloadAction<Order | null>) => {
      state.order = action.payload;
      state.isSendModalOpen = true;
    },
    closeSendModal: (state: OrdersStore, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) state.order = null;
      state.isSendModalOpen = false;
    },
  },
});

export const actions = ordersSlice.actions;

export const ordersStore = (state: RootState) => state.orders;

export default ordersSlice.reducer;
