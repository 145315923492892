import DatePicker, { DateRange, RangeType } from 'rsuite/DateRangePicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import './i18n';
import { useTheme } from 'styled-components';
import { useMediaQuery } from '@mui/material';
import { DateRangePickerProps } from './types';
import { DateContainer } from './styles';

const DateRangePicker = ({ value, onChange }: DateRangePickerProps) => {
  const { t, ready } = useTranslation('dateRangePicker');
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const ranges: RangeType[] = useMemo(
    (): RangeType[] => [
      {
        label: t('today'),
        value: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
      {
        label: t('yesterday'),
        value: [dayjs().add(-1, 'day').startOf('day').toDate(), dayjs().add(-1, 'day').endOf('day').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
      {
        label: t('last7Days'),
        value: [dayjs().add(-6, 'day').startOf('day').toDate(), dayjs().endOf('day').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
      {
        label: t('currentMonth'),
        value: [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
      {
        label: t('previousMonth'),
        value: [dayjs().add(-1, 'month').startOf('month').toDate(), dayjs().add(-1, 'month').endOf('month').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
      {
        label: t('firstQuarter'),
        value: [dayjs().set('month', 0).startOf('month').toDate(), dayjs().set('month', 2).endOf('month').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
      {
        label: t('secondQuarter'),
        value: [dayjs().set('month', 3).startOf('month').toDate(), dayjs().set('month', 5).endOf('month').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
      {
        label: t('thirdQuarter'),
        value: [dayjs().set('month', 6).startOf('month').toDate(), dayjs().set('month', 8).endOf('month').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
      {
        label: t('fourthQuarter'),
        value: [dayjs().set('month', 9).startOf('month').toDate(), dayjs().set('month', 11).endOf('month').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
      {
        label: t('currentYear'),
        value: [dayjs().startOf('year').toDate(), dayjs().endOf('year').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
      {
        label: t('previousYear'),
        value: [dayjs().add(-1, 'year').startOf('year').toDate(), dayjs().add(-1, 'year').endOf('year').toDate()],
        placement: 'left',
        closeOverlay: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, ready],
  );

  const handleChange = (range: DateRange) => {
    if (onChange) {
      onChange({
        start: dayjs(range[0]).startOf('day'),
        end: dayjs(range[1]).endOf('day'),
      });
    }
  };

  return (
    <DateContainer>
      <DatePicker
        placement="bottomEnd"
        ranges={ranges}
        character=" - "
        isoWeek
        cleanable={false}
        format="dd/MM/yyyy"
        placeholder="DD/MM/YYYY - DD/MM/YYYY"
        onOk={handleChange}
        showOneCalendar={isMdDown}
        size="lg"
        value={value ? [value.start.toDate(), value.end.toDate()] : null}
      />
    </DateContainer>
  );
};

export default DateRangePicker;
