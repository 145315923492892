import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import Logo from 'src/components/Logo/Logo';
import { NavbarProps } from './types';
import MENU from '../../menu';
import { useTranslation } from 'react-i18next';

const Navbar = ({ isOpen, onNavbarToggle, onNavigate }: NavbarProps) => {
  const { t } = useTranslation('header');
  const drawer = (
    <Box onClick={onNavbarToggle} sx={{ textAlign: 'center' }}>
      <Logo size="medium" />
      <List>
        {MENU.map(item => (
          <>
            <ListItem key={item.key} disablePadding onClick={onNavigate(item.path)}>
              <ListItemButton>
                <ListItemText primary={t(item.key)} />
              </ListItemButton>
            </ListItem>
            {item.options && item.options.length > 0 && (
              <List sx={{ pl: 2, pb: 0, pt: 0 }}>
                {item.options?.map(opt => (
                  <ListItem key={opt.key} disablePadding onClick={onNavigate(opt.path)}>
                    <ListItemButton>
                      <ListItemText primary={t(opt.key)} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </>
        ))}
      </List>
    </Box>
  );

  return (
    <nav>
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={onNavbarToggle}
        ModalProps={{ keepMounted: true }}
        sx={{ display: { xs: 'block', md: 'none' } }}>
        {drawer}
      </Drawer>
    </nav>
  );
};

export default Navbar;
