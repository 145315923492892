import { RoleEnum } from 'src/types/auth';
import ROUTES from 'src/routes/routes';
import { HeaderItemType } from 'src/types/global';

const MENU: HeaderItemType[] = [
  {
    key: 'orders',
    icon: 'home',
    path: ROUTES.ADMIN,
    roles: [RoleEnum.USER, RoleEnum.ADMIN],
  },
  {
    key: 'configuration',
    icon: 'settings',
    roles: [RoleEnum.USER, RoleEnum.ADMIN],
    options: [
      {
        key: 'users',
        icon: 'home',
        path: ROUTES.USERS,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
      {
        key: 'clients',
        icon: 'home',
        path: ROUTES.CLIENTS,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
      {
        key: 'suppliers',
        icon: 'home',
        path: ROUTES.SUPPLIERS,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
      {
        key: 'smpts',
        icon: 'home',
        path: ROUTES.SMTPS,
        roles: [RoleEnum.USER, RoleEnum.ADMIN],
      },
    ],
  },
];

export default MENU;
