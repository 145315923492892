const ROUTES = {
  INDEX: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  ADMIN: '/admin',
  ORDERS: '/orders',
  USERS: '/users',
  SMTPS: '/smtps',
  CLIENTS: '/clients',
  CLIENT: '/clients/:id/',
  SUPPLIERS: '/suppliers',
  SUPPLIER: '/suppliers/:id/',
};

export default ROUTES;
