import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Content = styled.div<{ includeFooter: boolean }>`
  flex: 1;
  height: ${({ includeFooter }) => `calc(100% - ${includeFooter ? '64px' : '0px'} - var(--header-height, 64))`};
  padding: 8px 8px 8px 16px;
`;

export const ScrollContainer = styled.div`
  padding: 8px;
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.background.default};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.grey[400]};
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.grey[500]};
  }
`;

export const Footer = styled.footer`
  height: 64px;
  box-shadow: rgba(0, 0, 0, 0.2) -2px 2px 10px 1px;
  gap: 16px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
