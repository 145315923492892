import { configureStore } from '@reduxjs/toolkit';
import global from './global/global';
import auth from './auth/auth';
import users from './users/users';
import smtps from './smtps/smtps';
import orders from './orders/orders';
import clients from './clients/clients';
import client from './clients/client';
import suppliers from './suppliers/suppliers';
import supplier from './suppliers/supplier';
import { apiSlice } from './api';

const reducer = {
  global,
  auth,
  users,
  smtps,
  orders,
  clients,
  client,
  suppliers,
  supplier,
  [apiSlice.reducerPath]: apiSlice.reducer,
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
