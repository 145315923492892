import { useFormik } from 'formik';
import { SendEmailForm, validationSchema } from './schema';
import dayjs from 'dayjs';
import { UseSendEmailModalArgs } from './types';
import { useEffect } from 'react';
import { SupplierContact } from 'src/types/suppliers';

const initialValues: SendEmailForm = {
  smtp: null,
  to: [],
  body: '',
  subject: '',
  isProgrammed: false,
  programDate: dayjs(),
};

export const useSendEmailModal = ({ isOpen, defaultBody, defaultSubject, onSend }: UseSendEmailModalArgs) => {
  const form = useFormik<SendEmailForm>({
    initialValues,
    validationSchema,
    onSubmit: values =>
      onSend({
        ...values,
        smtpId: values.smtp.id,
        programDate: values.isProgrammed && values.programDate ? dayjs(values.programDate).format('YYYY-MM-DD') : '',
      }),
  });

  const handleReceivers = (values: (string | SupplierContact | undefined)[]) => {
    const receivers = values.filter(v => v).map(v => (typeof v === 'string' ? v : v?.email));
    form.setFieldValue('to', receivers);
  };

  useEffect(() => {
    form.resetForm({
      values: { ...initialValues, body: defaultBody || '', subject: defaultSubject || '' },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBody, defaultSubject]);

  useEffect(() => {
    if (!isOpen) form.resetForm({ values: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return { form, handleReceivers };
};
