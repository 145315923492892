import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'es',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.addResourceBundle(
  'es',
  'common',
  {
    roleList: {
      admin: 'Administrador',
      user: 'Usuario',
    },

    roles: ['admin', 'user'],
    actives: 'Activos',
    inactives: 'Inactivos',

    actions: {
      delete: 'Eliminar',
      edit: 'Editar',
      duplicate: 'Duplicar',
      saveChanges: 'Guardar cambios',
      save: 'Guardar',
      send: 'Enviar',
      cancel: 'Cancelar',
      back: 'Volver',
      create: 'Crear',
    },

    errorMessage: 'Ha ocurrido un error. Intente nuevamente',
  },
  true,
  false,
);

export default i18n;
