import styled from 'styled-components';

export const AppContainer = styled.div.attrs({ id: 'app-container ' })`
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  overflow: hidden;

  --header-height: 64px;

  .MuiToolbar-root {
    height: var(--header-height, 64);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    --header-height: 56px;
  }
`;
