import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

const HoverStyles = css`
  border: 4px dashed ${({ theme }) => theme.palette.divider};
`;

export const DropZoneContainer = styled.div<{ hover: boolean; error: boolean }>`
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.palette.background.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({ theme, error }) => (error ? theme.palette.error.main : theme.palette.divider)};
  color: ${({ theme }) => theme.palette.grey[500]};
  will-change: border;
  transition: border 0.3s;
  margin-bottom: 4px;
  padding: 0 16px;

  & h6 {
    color: ${({ theme }) => theme.palette.grey[500]};
    font-weight: 500;
  }

  & span {
    font-weight: 500;
  }

  ${({ hover }) => hover && HoverStyles};
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const Link = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 500;
  cursor: pointer;
`;

export const HelperMessage = styled(Typography)`
  height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const FileName = styled(Typography)`
  flex: 1;
  color: ${({ theme }) => theme.palette.text.primary} !important;
  font-weight: 600;
  margin-right: 16px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
