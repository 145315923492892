import { Button } from '@mui/material';
import styled from 'styled-components';

export const SIDE_PANEL_WIDTH = 420;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
`;

export const PdfContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.palette.grey[400]};
  padding: 16px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;

  .react-pdf__Page {
    margin-bottom: 16px;
  }

  .react-pdf__Page:last-child {
    margin-bottom: 0;
  }
`;

export const ExpandIndicator = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 24px;
`;

export const ExpandIcon = styled.div`
  width: 64px;
  height: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  opacity: 0.6;
  transition: opacity 0.25s ease-in-out;

  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const PanelContainer = styled.div<{ isOpen?: boolean }>`
  width: ${SIDE_PANEL_WIDTH}px;
  min-width: ${SIDE_PANEL_WIDTH}px;
  height: 100%;
  overflow-y: hidden;
  max-height: calc(100vh - var(--header-height, 64));
  background-color: ${({ theme }) => theme.palette.background.paper};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    box-shadow:
      0 4px 8px 0 rgba(0, 0, 0, 0.4),
      0 6px 20px 0 rgba(0, 0, 0, 0.35);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    min-width: 100%;
    height: ${({ isOpen }) => (isOpen ? 'calc(100vh - 72px)' : '160px')};
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    transition: height 0.3s ease-in-out;

    ${ExpandIndicator} {
      display: flex;
    }
  }
`;

export const TabContainer = styled.div`
  padding: 8px;
  height: 100%;
  overflow: hidden;
  max-height: calc(100vh - var(--header-height) - 48px);
`;

export const TabContent = styled.div`
  padding: 8px;
  height: 100%;

  width: 100%;
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.background.default};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.grey[400]};
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.grey[500]};
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const OutlinedIconButton = styled(Button)`
  padding: 8px;
  min-width: auto;
  border: 1px solid ${({ theme }) => theme.palette.background.paper};
`;

export const PrimaryButton = styled(Button)`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.background.paper};
`;
